import "./Header.scss";

export default function Header() {
  return (
    <header className="header">
      <h1 className="glitch" style={{ "--stacks": 3 }}>
        <span style={{ "--index": 0 }}>NiceRain</span>
        <span style={{ "--index": 1 }}>NiceRain</span>
        <span style={{ "--index": 2 }}>NiceRain</span>
      </h1>
      <p className="subheading">I play games and build things.</p>
    </header>
  );
}
