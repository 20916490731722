export default function FFXIV() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1662.000000 1790.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1790.000000) scale(0.100000,-0.100000)"
        fill="#fae5df"
        stroke="#a96a70"
        strokeWidth="700"
      >
        <path
          d="M14041 17854 c-18 -15 -66 -72 -108 -126 -263 -348 -498 -567 -728
-679 -236 -115 -438 -119 -716 -15 -220 83 -237 82 -328 -1 -212 -192 -419
-340 -601 -427 -141 -68 -237 -95 -357 -103 -166 -11 -288 28 -486 155 -141
90 -166 102 -210 102 -45 0 -80 -24 -197 -137 -134 -128 -228 -256 -374 -505
-168 -287 -240 -382 -371 -487 -186 -151 -430 -260 -767 -342 -134 -32 -140
-33 -222 -21 -46 7 -174 39 -283 72 -310 93 -392 111 -533 117 -206 8 -344
-35 -466 -149 -90 -85 -109 -118 -158 -283 -101 -338 -180 -544 -306 -800
-233 -474 -488 -725 -739 -725 -91 0 -406 93 -595 176 -332 146 -454 304 -371
482 48 103 204 231 400 330 44 22 87 48 96 58 28 32 43 96 43 179 0 151 -56
244 -151 251 -398 29 -483 52 -580 157 -89 96 -115 182 -118 387 -2 134 -5
161 -23 195 -68 134 -165 206 -237 179 -20 -8 -227 -249 -328 -381 -156 -207
-265 -385 -452 -743 -59 -113 -126 -235 -151 -271 -82 -124 -206 -233 -285
-250 -24 -6 -94 -3 -191 6 -250 24 -335 18 -380 -27 -19 -19 -20 -29 -15 -112
5 -100 38 -217 91 -325 24 -51 40 -70 75 -90 224 -132 389 -327 421 -500 14
-77 12 -200 -5 -315 -19 -126 -19 -189 0 -226 8 -16 45 -54 83 -84 117 -94
152 -141 152 -202 0 -66 -98 -176 -196 -221 -48 -22 -66 -25 -132 -21 -100 5
-158 31 -292 131 -121 91 -137 100 -155 93 -7 -2 -37 -49 -66 -103 -149 -278
-301 -472 -472 -605 -321 -247 -682 -260 -1115 -38 -65 32 -214 123 -332 200
-217 142 -278 173 -308 154 -61 -39 -74 -132 -33 -240 12 -34 60 -128 106
-208 l83 -147 -38 -112 c-52 -152 -73 -271 -68 -378 7 -138 44 -176 144 -144
82 26 283 16 414 -20 201 -55 346 -159 385 -274 52 -156 -26 -326 -244 -533
-94 -89 -288 -240 -392 -304 -71 -45 -101 -102 -107 -211 -3 -61 0 -80 21
-126 33 -72 94 -120 290 -227 224 -122 282 -169 328 -265 49 -101 38 -209 -34
-352 l-27 -51 34 -74 c67 -147 145 -416 174 -598 37 -231 40 -505 8 -695 -91
-534 -393 -869 -886 -981 -94 -21 -401 -51 -419 -40 -3 2 -48 6 -100 7 -90 3
-94 2 -107 -21 -23 -42 -18 -168 11 -258 83 -263 390 -713 738 -1082 44 -47
107 -109 141 -138 l60 -52 86 6 c344 26 679 -215 890 -641 126 -254 173 -441
174 -695 1 -196 -15 -279 -76 -403 -78 -156 -211 -258 -381 -293 -110 -22
-127 -32 -149 -82 -15 -33 -18 -56 -14 -86 8 -49 47 -112 76 -121 11 -3 20
-12 20 -19 0 -26 56 -34 530 -76 1216 -110 1978 -397 2492 -939 140 -148 176
-247 198 -559 14 -206 29 -266 75 -310 44 -41 94 -42 220 -6 243 69 421 147
712 311 261 147 374 188 498 181 62 -3 85 -10 205 -69 74 -37 212 -115 305
-173 307 -193 463 -256 688 -277 173 -17 250 6 353 103 55 52 95 123 183 328
106 244 282 398 560 489 102 34 274 71 327 71 18 0 88 -18 154 -39 319 -105
546 -231 723 -400 101 -96 169 -187 253 -340 105 -190 132 -210 234 -180 50
15 125 -5 318 -87 299 -126 410 -151 467 -102 22 20 28 41 50 168 67 396 189
712 385 992 81 118 305 342 425 426 253 179 517 290 955 403 263 68 326 87
388 116 l52 24 0 73 c0 125 -39 387 -91 606 -21 91 -21 94 -4 209 23 156 61
338 91 432 44 139 252 383 382 446 53 26 68 28 222 34 120 4 178 11 215 24
180 64 391 227 449 348 15 31 33 99 45 170 21 131 46 192 117 286 62 82 215
224 283 263 94 53 258 164 334 227 40 32 140 123 223 202 83 79 189 171 235
204 112 81 353 223 533 315 115 59 148 80 153 99 3 14 7 91 7 171 l1 146 -30
43 c-88 126 -117 189 -163 361 -37 135 -82 387 -117 652 -7 53 -28 73 -68 63
-20 -5 -25 -18 -41 -98 -62 -316 -156 -618 -254 -812 -155 -311 -346 -467
-649 -531 -73 -16 -131 -19 -335 -19 l-246 1 -54 33 c-178 111 -339 255 -422
380 -26 39 -62 108 -79 153 l-32 83 0 207 c0 194 -1 208 -21 235 -14 18 -32
29 -52 31 -82 8 -152 200 -152 415 0 120 11 167 49 210 22 24 37 31 67 31 22
1 49 -3 62 -7 22 -8 23 -7 15 30 -4 21 -8 83 -8 138 0 89 3 106 28 157 48 97
141 158 244 158 24 0 43 4 43 9 0 5 11 76 25 157 50 294 104 444 215 593 38
51 75 112 81 135 13 46 7 200 -12 286 -18 86 -24 90 -148 90 -140 0 -183 19
-223 100 -36 72 -56 197 -38 241 21 50 83 86 219 129 151 48 198 73 222 120
25 46 24 93 -4 160 -41 99 -109 188 -305 397 -256 273 -342 444 -342 678 0
285 171 607 406 764 96 65 205 105 394 148 297 67 360 126 360 333 l0 87 -51
7 c-75 10 -150 41 -198 82 -52 45 -71 81 -71 136 0 39 7 51 73 128 73 85 201
199 287 257 136 92 296 147 480 168 30 3 60 9 66 13 6 4 16 66 22 142 14 161
30 221 87 320 24 41 49 95 56 120 19 65 19 371 0 393 -7 10 -17 17 -22 17 -8
0 -332 -257 -414 -328 -22 -19 -95 -88 -162 -154 l-122 -120 -29 21 c-54 38
-136 53 -323 58 l-176 5 -21 -58 c-49 -135 -52 -189 -17 -374 31 -166 27 -203
-25 -230 -92 -47 -132 -134 -158 -345 -10 -79 -24 -151 -31 -159 -9 -10 -30
-16 -60 -16 -25 0 -89 -11 -141 -25 -194 -51 -225 -57 -290 -53 -109 6 -198
68 -298 208 -26 36 -53 70 -62 77 -29 23 -105 26 -255 10 -238 -27 -303 -30
-410 -18 -107 11 -207 35 -293 70 l-53 21 -15 -36 c-8 -19 -22 -85 -30 -145
-8 -61 -22 -129 -30 -152 -46 -132 -174 -236 -370 -301 -48 -16 -52 -15 -109
4 -106 36 -185 99 -255 203 -34 51 -70 128 -140 295 -29 69 -55 115 -78 138
l-35 34 5 92 c3 51 16 134 28 184 15 59 21 101 16 120 -17 67 -217 216 -291
216 -107 0 -160 -130 -153 -376 l3 -129 -50 -4 c-94 -7 -159 -38 -322 -150
-103 -71 -163 -97 -210 -89 -18 2 -70 32 -116 65 -171 124 -302 163 -481 143
-163 -19 -239 -12 -322 31 -107 55 -223 268 -276 502 -25 115 -36 401 -19 513
27 176 70 268 178 377 359 366 665 485 1003 391 47 -13 148 -49 225 -81 213
-88 290 -106 346 -82 19 8 77 43 130 79 55 38 123 74 161 87 179 60 396 37
637 -66 l85 -36 15 24 c29 46 104 112 155 135 85 38 171 48 381 41 313 -11
367 9 432 156 61 138 176 357 242 462 94 149 185 265 323 414 172 186 222 246
275 324 149 223 229 583 248 1122 6 153 5 174 -12 205 -24 47 -67 52 -112 14z
m-6036 -5304 c568 -47 1013 -135 1525 -302 674 -219 1269 -543 1795 -979 128
-105 466 -444 574 -574 698 -840 1094 -1878 1206 -3159 40 -454 27 -847 -41
-1256 -220 -1327 -1010 -2506 -2224 -3317 -919 -614 -2038 -963 -3095 -966
-940 -2 -1965 327 -2850 917 -1139 758 -1929 1887 -2203 3152 -97 446 -128
828 -112 1414 14 527 52 823 161 1239 363 1398 1369 2586 2789 3295 583 291
1162 469 1720 530 150 16 588 20 755 6z"
        />
        <path
          d="M16413 9436 c-61 -29 -124 -89 -153 -146 -12 -23 -37 -87 -57 -143
-41 -120 -53 -144 -104 -216 -46 -64 -49 -98 -19 -217 47 -184 176 -384 248
-384 59 0 76 49 122 368 46 322 67 407 137 565 30 69 33 81 23 111 -26 80
-106 106 -197 62z"
        />
      </g>
    </svg>
  );
}
