import "./App.scss";
import { Header, Link } from "./components/";

function App() {
  return (
    <div className="body">
      <div className="container">
        <div className="centeredcontent">
          {/* Header */}
          <Header />

          {/* Main Content */}
          <main className="main">
            <ul>
              <Link
                title="Discord"
                icon="discord"
                url="https://discordapp.com/users/240634806033973248"
              />
              <Link
                title="VRChat"
                icon="vrc"
                url="https://vrchat.com/home/user/usr_41d73d54-e3fe-412d-8972-0746a9d9f08a"
              />
              <Link
                title="Epic/Fortnite"
                icon="epic"
                url="https://store.epicgames.com/en-US/u/f2167ab15d8a478088a3f8c4dfbe5abc"
              />
              <Link
                title="Anilist"
                icon="anilist"
                url="https://anilist.co/user/nicerain/"
              />
              <Link
                title="Instagram"
                icon="instagram"
                url="https://www.instagram.com/nicera.in/"
              />
              <Link
                title="Steam"
                icon="steam"
                url="https://steamcommunity.com/id/nice_rain/"
              />
              <Link
                title="FFXIV"
                icon="ffxiv"
                url="https://na.finalfantasyxiv.com/lodestone/character/11059207/"
              />
            </ul>
          </main>
        </div>

        {/* Footer */}
        <footer className="footer">Copyright © 2022 NiceRain</footer>
      </div>
    </div>
  );
}

export default App;
