import "./Link.scss";
import Anilist from "../Icons/Anlist";
import Discord from "../Icons/Discord";
import FFXIV from "../Icons/FFXIV";
import Instagram from "../Icons/Instagram";
import Steam from "../Icons/Steam";
import Epic from "../Icons/Epic";
import VRChat from "../Icons/VRChat";

export default function Link(props) {
  const getIcon = () => {
    switch (props.icon) {
      case "anilist":
        return <Anilist />;
      case "discord":
        return <Discord />;
      case "ffxiv":
        return <FFXIV />;
      case "instagram":
        return <Instagram />;
      case "steam":
        return <Steam />;
      case "epic":
        return <Epic />;
      case "vrc":
        return <VRChat />;
      default:
        return <></>;
    }
  };

  return (
    <li className="link">
      <a href={props.url} target="_blank" rel="noreferrer">
        {getIcon()}
        <p>{props.title}</p>
      </a>
    </li>
  );
}
