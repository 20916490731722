export default function VRChat() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 192 192"
      enableBackground="new 0 0 192 192"
      height="1em"
      width="1em"
    >
      <path
        fill="#FCFCFC"
        opacity="1.000000"
        stroke="none"
        d="
M183.856949,109.992630 
	C183.856689,113.657021 183.870392,116.823158 183.853607,119.989120 
	C183.796158,130.824310 178.078369,136.579041 167.200104,136.755585 
	C165.912857,136.776474 164.624985,136.758408 162.972412,136.758408 
	C162.972412,150.103012 162.972366,163.068039 162.972488,176.033066 
	C162.972488,177.032867 163.165543,178.079803 162.934143,179.022675 
	C162.451721,180.988342 161.745132,182.899017 161.130646,184.832275 
	C159.348785,183.796265 157.064178,183.161819 155.864929,181.660446 
	C146.615021,170.080185 137.023972,158.709244 128.669891,146.499420 
	C123.357346,138.734940 117.422630,136.271301 108.141464,136.513977 
	C81.498169,137.210648 54.824848,136.768280 28.163338,136.754990 
	C15.983848,136.748917 10.556714,131.286896 10.550968,119.014786 
	C10.536691,88.520683 10.533278,58.026558 10.552968,27.532457 
	C10.560694,15.568340 16.024973,10.107084 27.922356,10.105542 
	C74.080109,10.099558 120.237862,10.099613 166.395615,10.105369 
	C178.235718,10.106845 183.847748,15.696679 183.852859,27.510456 
	C183.864655,54.838394 183.856827,82.166344 183.856949,109.992630 
M110.760010,34.095791 
	C103.662483,34.155945 102.967560,34.822464 102.965218,41.722649 
	C102.958168,62.533276 102.956894,83.343903 102.966415,104.154533 
	C102.968903,109.602959 104.788078,111.534248 109.842522,111.546944 
	C114.879829,111.559593 116.783379,109.560997 116.796135,104.158241 
	C116.815269,96.052353 116.801117,87.946388 116.801117,80.199013 
	C123.369102,78.156799 126.737129,80.487473 129.164627,86.194908 
	C132.343536,93.669029 136.397995,100.777565 140.203796,107.975700 
	C142.513504,112.344170 145.567032,113.168816 149.649033,110.825005 
	C153.789551,108.447624 154.667267,105.568016 152.439072,101.381943 
	C148.237595,93.488708 143.997864,85.615829 139.773254,77.733055 
	C151.336731,71.751022 155.570114,62.509331 152.808838,49.793659 
	C150.715714,40.154858 142.990829,34.599442 130.678772,34.171764 
	C124.359657,33.952267 118.027489,34.108929 110.760010,34.095791 
M92.232452,47.836361 
	C92.750221,46.258286 93.181259,44.645142 93.802467,43.108894 
	C95.484276,38.949745 93.834717,36.465267 89.940918,34.943787 
	C84.583206,32.850288 82.063560,34.082836 80.457687,39.794205 
	C75.507278,57.400581 70.589432,75.016106 65.355499,93.711952 
	C64.647774,91.715759 64.277122,90.833366 64.019379,89.919136 
	C59.285831,73.129204 54.576801,56.332355 49.836296,39.544395 
	C48.387917,34.415127 46.318977,33.197559 41.562565,34.541790 
	C36.426483,35.993328 35.143002,38.240768 36.691681,43.317184 
	C42.944828,63.814430 49.342674,84.268646 55.415455,104.819023 
	C56.960114,110.046181 60.079750,112.110764 65.186684,112.126190 
	C70.284950,112.141594 73.467697,110.162170 75.006966,104.896088 
	C80.506348,86.081924 86.336761,67.364517 92.232452,47.836361 
z"
      />
      <path
        fill="#060606"
        opacity="1.000000"
        stroke="none"
        d="
M111.230614,34.095695 
	C118.027489,34.108929 124.359657,33.952267 130.678772,34.171764 
	C142.990829,34.599442 150.715714,40.154858 152.808838,49.793659 
	C155.570114,62.509331 151.336731,71.751022 139.773254,77.733055 
	C143.997864,85.615829 148.237595,93.488708 152.439072,101.381943 
	C154.667267,105.568016 153.789551,108.447624 149.649033,110.825005 
	C145.567032,113.168816 142.513504,112.344170 140.203796,107.975700 
	C136.397995,100.777565 132.343536,93.669029 129.164627,86.194908 
	C126.737129,80.487473 123.369102,78.156799 116.801117,80.199013 
	C116.801117,87.946388 116.815269,96.052353 116.796135,104.158241 
	C116.783379,109.560997 114.879829,111.559593 109.842522,111.546944 
	C104.788078,111.534248 102.968903,109.602959 102.966415,104.154533 
	C102.956894,83.343903 102.958168,62.533276 102.965218,41.722649 
	C102.967560,34.822464 103.662483,34.155945 111.230614,34.095695 
M116.790237,61.433880 
	C116.790237,63.837803 116.790237,66.241730 116.790237,68.665230 
	C121.891594,68.665230 126.226250,68.999908 130.488388,68.586716 
	C136.867828,67.968277 139.836395,64.038132 139.714172,57.238930 
	C139.595810,50.654301 136.635300,46.857738 130.523895,46.316097 
	C126.116997,45.925522 121.647621,46.239990 116.790230,46.239990 
	C116.790230,51.222561 116.790230,55.838356 116.790237,61.433880 
z"
      />
      <path
        fill="#060606"
        opacity="1.000000"
        stroke="none"
        d="
M92.137192,48.223434 
	C86.336761,67.364517 80.506348,86.081924 75.006966,104.896088 
	C73.467697,110.162170 70.284950,112.141594 65.186684,112.126190 
	C60.079750,112.110764 56.960114,110.046181 55.415455,104.819023 
	C49.342674,84.268646 42.944828,63.814430 36.691681,43.317184 
	C35.143002,38.240768 36.426483,35.993328 41.562565,34.541790 
	C46.318977,33.197559 48.387917,34.415127 49.836296,39.544395 
	C54.576801,56.332355 59.285831,73.129204 64.019379,89.919136 
	C64.277122,90.833366 64.647774,91.715759 65.355499,93.711952 
	C70.589432,75.016106 75.507278,57.400581 80.457687,39.794205 
	C82.063560,34.082836 84.583206,32.850288 89.940918,34.943787 
	C93.834717,36.465267 95.484276,38.949745 93.802467,43.108894 
	C93.181259,44.645142 92.750221,46.258286 92.137192,48.223434 
z"
      />
      <path
        fill="#F8F8F8"
        opacity="1.000000"
        stroke="none"
        d="
M116.790237,60.944016 
	C116.790230,55.838356 116.790230,51.222561 116.790230,46.239990 
	C121.647621,46.239990 126.116997,45.925522 130.523895,46.316097 
	C136.635300,46.857738 139.595810,50.654301 139.714172,57.238930 
	C139.836395,64.038132 136.867828,67.968277 130.488388,68.586716 
	C126.226250,68.999908 121.891594,68.665230 116.790237,68.665230 
	C116.790237,66.241730 116.790237,63.837803 116.790237,60.944016 
z"
      />
    </svg>
  );
}
